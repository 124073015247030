.event-card {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	
	&__name {
		font-size: 1.15rem;
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr 3fr;
		grid-template-rows: auto auto;
		gap: 0.7rem;
		margin-top: 1.15rem;
		margin-bottom: 1.15rem;
	}

	&__grid > .labeled-text:first-child {
		grid-column: 1 / 3;
	}
}