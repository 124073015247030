.members {
	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		width: min(100%, 70rem);
		margin-bottom: 0.75rem;
	}

	&__table {
		overflow-x: scroll;
	}

	&__tableContent {
		width: min(100%, 70rem);
		border-collapse: collapse;
	}

	&__tableHeader {
		background: var(--lightbrown);
		color: white;
	}

	&__tableHeader > th,
	&__tableRow > td {
		text-align: left;
		padding: 0.625rem 0.8rem 0.625rem 0.625rem;
	}

	&__tableHeader > th:first-child,
	&__tableRow:nth-child(even) > td:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	
	&__tableHeader > th:last-child,
	&__tableRow:nth-child(even) > td:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	&__tableRow:nth-child(even) {
		background: var(--beige);
	}

	&__tableContainer {
		display: flex;
		align-items: center;
		width: fit-content;
		gap: 0.25rem;
		min-width: 9rem;
	}

	&__tableInput {
		padding-inline: 0.5rem;
		height: 2rem;
		width: 4.5rem;
		border-radius: 5px;
		border: none;
		background-color: var(--beige-light1);
		font-family: inherit;
		font-size: 1rem;
		transition: all 300ms ease;
	
		&:hover {
			background-color: var(--beige); 
		}
		
		&:focus {
			outline: none;
			background-color: var(--beige-dark1);
		}
		
		&:disabled {
			background-color: var(--gray-dark2);
			color: var(--darkgray);
		}

		&--unsaved {
			background-color: var(--darkbrown);
			color: white;
		}

		&--unsaved:hover {
			background-color: var(--darkbrown-dark1);
		}

		&--unsaved:focus {
			background-color: var(--darkbrown-dark2);
		}
	}

	&__icon {
		width: 1rem;
	}
}