.small-button {
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  font-size: 1.15rem;
  font-weight: 500;
  color: var(--lightbrown-dark1);
  transition: all 300ms ease;
}

.small-button:hover {
	background-color: var(--beige-dark1);
}

.small-button:active {
	background-color: var(--beige-dark2);
}

.small-button--margin {
	margin-left: -0.625rem;
  margin-right: 0.3125rem;
}

.small-button--md {
  margin-top: -1rem;
  margin-bottom: 3rem;
  padding: 0.375rem 1.25rem;
  background-color: var(--beige);
}