.card {
	width: 100%;
  height: auto;
	padding: 1.2rem 1.4rem;
  border-radius: 5px;
  background-color: var(--beige);
	
	@media (min-width: 700px) {
		padding: 1.5rem 1.7rem;
	}
}