.clubs {
	&__heading {
		font-size: 1.15rem;
	}
	
	&__checkIn {
		display: block;
		width: 100%;
		margin-bottom: 1.25rem;
		padding: 0.8rem 1.4rem;
		border-radius: 5px;
		font-size: 1.15rem;
		text-align: center;
		background-color: var(--darkbrown);
		color: #ffffff;
		transition: all 300ms ease;
		
		@media (min-width: 700px) {
			width: fit-content;
			padding: 0.9rem 1.7rem;
			font-size: 1.25rem;
		}
	}
	
	&__checkIn:hover {
		background-color: var(--darkbrown-dark1);
	}

	&__checkIn:active {
		background-color: var(--darkbrown-dark2);
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(100%, 18rem), 1fr));
		gap: 1.25rem;
	}
}