.event-details {
	&__grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(100%, 10rem), auto));
		gap: 1rem;
	  margin-bottom: 3rem;
	}

	&__invite {
		margin-bottom: 3rem;
	}

	&__inviteTitle {
		margin-bottom: 0.75rem;
	}

	&__inviteBox {
		width: 100%;
		padding: 1rem 1.5rem;
		border-radius: 5px;
		background-color: var(--beige);

		@media (min-width: 700px) {
			width: fit-content;
		}
	}

	&__inviteText:first-child {
		margin-bottom: 0.5rem;
	}
}