.grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto;
	width: auto;
	
	@media(min-width: 700px) {
		gap: 3rem;
		grid-template-columns: 1fr 4fr;
		grid-template-rows: 100vh;
	}
}

.main {
	max-width: 100vw;
	padding: 0 1.125rem 1.125rem 1.125rem;

	@media(min-width: 700px) {
		padding: 4.1875rem 3rem 2rem 0;
		overflow-y: auto;
	}
}