.reset-password {
	&__logo {
		display: block;
		width: min(100%, 16rem);
		margin-inline: auto;
		margin-bottom: 2rem;
	}
	
	& .status-message {
		margin-bottom: 1rem;
	}

	&__form {
		margin-bottom: 1rem;
	}
}