.terms-of-service {
	&__h1 {
		text-align: center;
	}

	&__h2 {
		margin-block: 2rem;
	}

	&__h3 {
		margin-block: 1.5rem;
	}

	&__subtitle {
		display: block;
		margin-bottom: 4rem;
		text-align: center;
	}

	&__p {
		margin-bottom: 1rem;
	}

	&__link {
		color: var(--lightbrown);
		text-decoration: underline transparent;
		transition: all 300ms ease;

		&:hover {
			color: var(--lightbrown-dark1);
			text-decoration: underline currentColor;
		}

		&:active {
			color: var(--lightbrown-dark2);
		}
	}
}