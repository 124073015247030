.logo-color {
	&__logo {
		display: block;
		width: min(100%, 16rem);
		margin-inline: auto;
		margin-bottom: 2rem;

		&:hover {
			cursor: pointer;
		}
	}
}