.labeled-text {
	width: fit-content;
	
	&__text {
		margin-bottom: 0.25rem;
		font-size: 1.5rem;
		width: fit-content;
		padding: 0.2em 0.5rem;
		border-radius: 5px;
		background-color: white;
	}
}

.labeled-text--d > .labeled-text__text {
	padding: 0;
}

.labeled-text--cc > .labeled-text__text {
	margin-bottom: 0.5rem;
	font-size: 2rem;
}