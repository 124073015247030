h1, 
h2, 
h3 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 700;
	line-height: 1.1;
}

p {
	margin: 0;
}

a, 
a:visited, 
a:active {
	text-decoration: none;
}