.table {
	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		width: min(100%, 70rem);
		margin-bottom: 0.75rem;
	}

	&__title {
		margin-bottom: 0;
	}

	&__content {
		width: min(100%, 70rem);
		border-collapse: collapse;
	}

	&__header {
		background-color: var(--lightbrown);
		color: white;
	}

	&__header > th,
	&__row > td {
		text-align: left;
		padding: 0.625rem 0.8rem 0.625rem 0.625rem;
	}

	&__header > th:first-child,
	&__row:nth-child(even) > td:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	
	&__header > th:last-child,
	&__row:nth-child(even) > td:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	&__row:nth-child(even) {
		background-color: var(--beige);
	}

	&__addSelectSearch--hasFocus {
		& .table__addSelectSearchSelect {
			display: block;
		}
	}

	&__addSelectSearchContainer {
		position: relative;
	}

	&__addSelectSearchInput {
		position: relative;
		padding-inline: 0.625rem;
		height: 2rem;
		width: 100%;
		border-radius: 5px;
		border: none;
		background-color: var(--beige-light1);
		font-family: inherit;
		font-size: 1rem;
		text-overflow: ellipsis;
		transition: all 300ms ease;

		&::placeholder {
			color: var(--lightbrown);
		}

		&:hover {
			background-color: var(--beige); 
		}
		
		&:focus {
			outline: none;
			background-color: var(--beige-dark1);
		}

		&:disabled {
			background-color: var(--gray-dark2);
			cursor: not-allowed;

			&::placeholder {
				color: var(--darkgray);
			}
		}

		&--unsaved {
			background-color: var(--darkbrown);
			color: white;

			&:hover {
				background-color: var(--darkbrown-dark1); 
			}
			
			&:focus {
				outline: none;
				background-color: var(--darkbrown-dark2);
			}
		}
	}

	&__addSelectSearchSelect {
		position: absolute;
		top: 2.625rem;
		z-index: 1;
		width: 100%;
		min-width: 10rem;
		max-height: 8rem;
		padding: 0.5rem;
		border-radius: 5px;
		background-color: var(--beige-light1);
		filter: drop-shadow(0px 0px 10px var(--gray-dark2));
		overflow: auto;
		display: none;

		&--unsaved {
			background-color: var(--darkbrown);
		}
	}

	&__addSelectSearchOptions {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__addSelectSearchOption {
		width: 100%;
		padding-inline: 0.25rem;
		border-radius: 3px;
		background-color: inherit;
		font-size: inherit;
		text-align: left;
		transition: all 300ms ease;

		&:hover, &--isHighlighted {
			background-color: var(--beige);
		}

		&:active, &--isSelected {
			background-color: var(--beige-dark1);
		}

		&--unsaved {
			color: white;

			&:hover, &IsHighlighted {
				background-color: var(--darkbrown-dark1);
			}
	
			&:active, &IsSelected {
				background-color: var(--darkbrown-dark2);
			}
		}
	}

	&__addSelectSearchRow:not(:last-child) {
		margin-bottom: 0.15rem;
	}

	&__addSelectSearchEmptyMessage {
		text-align: center;
		color: var(--lightbrown);
		user-select: none;
	}

	&__data--withButtons {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		gap: 0.625rem;
	}
	
	&__data--add .icon-button {
		margin-left: -0.625rem;
		padding-inline: 0.625rem;
	}
	
	&__icon {
		height: 1rem;
		width: 1rem;
	}

	&__addLabel {
		margin-left: 0.25rem;
	}

	&--white {
		& .table__row:nth-child(even) {
			background-color: white;
		}
	}
}