.login {
	& .status-message {
		margin-bottom: 1rem;
	}
	
	&__form .text-input__label:last-of-type > .text-input__input {
		margin-bottom: 2rem;
	}

	&__or {
		margin-block: 1rem;
		text-align: center;
	}

	& .auth-link:nth-of-type(2) {
		margin-bottom: 1rem;
	}
}