.check-in {
	&__title {
		margin-bottom: 2rem;
		text-align: center;
	}
	
	& .status-message {
		margin-bottom: 1rem;
	}

	&__form {
		margin-bottom: 1rem;
	}
}