.create-account {
	& .status-message {
		margin-bottom: 1rem;
	}

	&__form {
		margin-bottom: 1rem;
	}

	&__form .text-input__label:last-of-type > .text-input__input {
		margin-bottom: 2rem;
	}

	&__accountType {
		margin-bottom: 1rem;
	}
	
	&__label {
		margin-bottom: 0.5rem;
	}

	&__radioContainer {
		display: flex;
		justify-content: space-evenly;
		gap: 2rem;
		padding: 0.75rem 1rem;
		border-radius: 5px;
		background-color: white;
	}

	&__radio {
		margin: 0 0.25rem 0 0;
	}
	
	&__or {
		margin-block: 1rem;
		text-align: center;
	}

	&__legal {
		display: block;
		margin-bottom: 1rem;
	}

	&__checkbox {
		margin-inline: 0 0.5rem;
	}

	&__link {
		color: var(--lightbrown);
		font-weight: 700;
		text-decoration: underline transparent;
		transition: all 300ms ease;

		&:hover {
			color: var(--lightbrown-dark1);
			text-decoration: underline currentColor;
		}

		&:active {
			color: var(--lightbrown-dark2);
		}
	}
}