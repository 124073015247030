.landing-page {
	min-height: 100vh;
	padding-inline: 2rem;
	padding-top: 6rem;
	background-color: var(--beige);

	@media(min-width: 700px) {
		padding-top: 8rem;
	}

	&__logo {
		display: block;
		width: 12rem;
		margin-inline: auto;
		margin-bottom: 1rem;
		
		@media(min-width: 700px) {
			width: 16rem;
		}
	}

	&__tagline {
		font-size: 1.25rem;
		font-weight: 400;
		text-align: center;
		margin-bottom: 2rem;

		@media(min-width: 700px) {
			margin-bottom: 4rem;
			font-size: 1.5rem;
		}
	}

	&__buttons {
		margin-bottom: 2rem;

		@media(min-width: 700px) {
			display: flex;
			gap: 1rem;
			width: fit-content;
			margin-inline: auto;
			margin-bottom: 4rem;
		}
	}

	&__buttons .auth-link:first-of-type {
		margin-bottom: 0.5rem;

		@media(min-width: 700px) {
			margin-bottom: 0;
		}
	}

	&__events-screenshot {
		display: block;
		width: min(100%, 65rem);
		margin-inline: auto;
		margin-bottom: 4rem;
		border-radius: 5px;

		@media(min-width: 700px) {
			margin-bottom: 6rem;
		}
	}

	&__heading {
		margin-bottom: 2rem;
		text-align: center;
		font-size: 1.75rem;
		color: var(--darkbrown);

		@media(min-width: 700px) {
			font-size: 2.5rem;
		}
	}

	&__steps {
		@media(min-width: 700px) {
			display: flex;
			justify-content: center;
			gap: 1rem;
			width: min(100%, 65rem);
			margin-inline: auto;
		}
	}
	
	&__admin, &__member {
		width: 100%;
		padding: 2rem 1.5rem;
		border-radius: 5px;
		
		@media(min-width: 700px) {
			padding: 3rem 2rem;
		}
	}
	
	&__subtitle {
		margin-bottom: 2rem;
		text-align: center;
		font-size: 1.25rem;

		@media(min-width: 700px) {
			margin-bottom: 3rem;
			font-size: 1.5rem;
		}
	}

	&__graphic {
		width: 100%;
		height: 14rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		color: black;
		user-select: none;
	}

	&__graphic:nth-child(3) {
		margin-bottom: 2rem;
	}
	
	&__admin {
		margin-bottom: 1rem;
		background-color: var(--lightbrown);
		color: white;

		@media(min-width: 700px) {
			margin-bottom: 0;
		}
	}

	&__admin &__graphic {
		background-color: white;
	}

	&__create {
		padding: 0.8rem 1.4rem;
		border-radius: 5px;
		font-size: 1.25rem;
		text-align: center;
		background-color: var(--darkbrown);
		color: #ffffff;
		
		@media (min-width: 700px) {
			padding: 0.9rem 1.7rem;
			font-size: 1.5rem;
		}
	}

	&__inviteTitle {
		margin-bottom: 0.75rem;
		font-size: 1.25rem;

		@media(min-width: 700px) {
			font-size: 1.5rem;
		}
	}

	&__inviteBox {
		padding: 1rem;
		border-radius: 5px;
		background-color: var(--beige);
		font-size: 0.9rem;

		@media (min-width: 700px) {
			width: fit-content;
		}
	}

	&__inviteText:first-child {
		margin-bottom: 0.5rem;
	}
	
	&__member {
		background-color: white;
		color: var(--darkbrown);
	}

	&__member &__graphic {
		background-color: var(--beige);
	}

	&__auth {
		width: 80%;
	}

	&__authLink {
		display: block;
		padding-block: 0.625rem;
		margin-inline: auto;
		border-radius: 5px;
		background-color: var(--darkbrown);
		font-size: 1.125rem;
		text-align: center;
		color: white;
	}

	&__authLink--createAccount {
		background-color: var(--lightbrown);
	}

	&__or {
		margin-block: 0.75rem;
		text-align: center;
		font-size: 1.125rem;
	}

	&__checkin {
		width: 80%;
	}

	&__checkinTitle {
		margin-bottom: 1.5rem;
		text-align: center;
		font-size: 1.25rem;

		@media(min-width: 700px) {
			font-size: 1.5rem;
		}
	}

	&__input {
		height: 2.75rem;
		margin-bottom: 0.75rem;
		border-radius: 5px;
		background-color: white;
	}

	&__footer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 0.5rem 1rem;
		padding: 3rem 2rem;
		white-space: nowrap;

		@media(min-width: 700px) {
			padding-block: 4rem;
		}
	}

	&__link {
		color: var(--lightbrown);
		text-decoration: underline transparent;
		transition: all 300ms ease;

		&:hover {
			color: var(--lightbrown-dark1);
			text-decoration: underline currentColor;
		}

		&:active {
			color: var(--lightbrown-dark2);
		}
	}

	&__legal {
		& .landing-page__link:first-child {
			margin-right: 1rem;
		}
	}
}