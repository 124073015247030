.numbered-text {
	&__number {
		display: inline-block;
		margin-right: 0.5rem;
		font-size: 1.25rem;

		@media(min-width: 700px) {
			font-size: 1.5rem;
		}
	}

	&__text {
		display: inline-block;
		font-size: 1.125rem;
	}
}