.navbar {
	margin: 1.125rem;
	padding: 1.25rem;
	overflow: hidden;
	background-color: var(--lightbrown);
	border-radius: 8px;

	@media (min-width: 700px) {
		margin: 2rem 0 2rem 2rem;
		padding: 2.1875rem;
		min-width: 12rem;
	}

	&__logo {
		display: inline-block;
		height: 2rem;
		margin-left: calc(50% - 1.25rem);
		margin-bottom: 1.25rem;
		color: white;

		@media (min-width: 700px) {
			display: inline;
			height: 3.125rem;
			margin-left: 0;
			margin-bottom: 6.7363rem;
		}
	}

	&__logo:hover {
		cursor: pointer;
	}

	&__menuToggle {
		float: right;
		margin-top: 0.5rem;
		height: 1rem;
		width: 1rem;
		color: white;
		cursor: pointer;
	}
	
	&__div {
		margin-left: -0.425rem;
		margin-right: -0.425rem;
		
		@media(min-width: 700px) {
			margin-left: -0.625rem;
			margin-right: -0.625rem;
		}
	}
}

.navbar--hidden {
	height: 4.5rem;
}