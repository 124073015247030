:root {
	--beige: #ede9e9;
	--beige-light1: #f6f4f4;
	--beige-dark1: #e3dddd;
	--beige-dark2: #dad2d2;

	--lightbrown: #ae9c98;
	--lightbrown-dark1: #9c8681;
	--lightbrown-dark2: #89716c;

	--darkbrown: #5e443e;
	--darkbrown-dark1: #4a3530;
	--darkbrown-dark2: #312420;

	--gray: #f6f6f6;
	--gray-dark1: #d9d9d9;
	--gray-dark2: #c1c1c1;

	--darkgray: #717171;
	--darkgray-dark1: #2f2f2f;

	--lightred: #f4cdcd;
	
	--red: #ac2424;
}