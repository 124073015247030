.nav-button {
	display: block;
	width: 100%;
	margin-bottom: 0.15rem;
	padding-block: 0.425rem;
	border-radius: 5px;
	background-color: transparent;
	font-size: 1.15rem;
	text-align: left;
	text-indent: 0.425rem;
	color: white;
	user-select: none;
	transition: background-color 300ms ease;
	
	@media (min-width: 700px) {
		margin-bottom: 0.1875rem;
		padding-top: 0.625rem;
		padding-bottom: 0.625rem;
		font-size: 1.25rem;
		text-indent: 0.625rem;
	}
	
	&:hover {
		background-color: var(--lightbrown-dark1);
	}
	
	&:active {
		background-color: var(--lightbrown-dark2);
	}
	
	&--current {
		background-color: var(--lightbrown-dark2);
		font-weight: 500;
	}
	
	&--short {
		padding-block: 0.25rem;
	}
}