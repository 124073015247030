.auth-link {
	display: block;
	width: 100%;
	padding-block: 0.625rem;
	border-radius: 5px;
	background-color: var(--lightbrown);
	font-size: 1rem;
	text-align: center;
	color: white;
	transition: background-color 300ms ease;

	&:hover {
		background-color: var(--lightbrown-dark1);
	}

	&:active {
		background-color: var(--lightbrown-dark2);
	}

	&--beige {
		background-color: var(--beige);
		color: black;

		&:hover {
			background-color: var(--beige-dark1);
		}

		&:active {
			background-color: var(--beige-dark2);
		}
	}

	&--darkbrown {
		background-color: var(--darkbrown);

		&:hover {
			background-color: var(--darkbrown-dark1);
		}
	
		&:active {
			background-color: var(--darkbrown-dark2);
		}
	}

	&--landing-page {
		padding-block: 0.8rem;
		font-size: 1.125rem;

		@media(min-width: 700px) {
			width: 14rem;
			font-size: 1.25rem;
		}
	}
}