.auth-button {
	display: block;
	width: 100%;
	padding-block: 0.625rem;
	border-radius: 5px;
	background-color: var(--darkbrown);
	font-size: 1rem;
	color: white;
	transition: background-color 300ms ease;

	&:hover {
		background-color: var(--darkbrown-dark1);
	}

	&:active {
		background-color: var(--darkbrown-dark2);
	}

	&--disabled, &--disabled:hover, &--disabled:active {
		background-color: var(--gray-dark2);
		color: var(--darkgray);
		cursor: not-allowed;
	}
}