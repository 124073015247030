.account {
	&__label {
		margin-bottom: 0.5rem;
		font-weight: 700;
	}

	&__data {
		margin-bottom: 1.5rem;
	}

	&__button {
		margin-right: 0.75rem;
  	padding: 0.375rem 1.25rem;
  	background-color: var(--beige);
  	font-size: 1rem;
		font-weight: 500;
  	color: var(--lightbrown-dark1);
		transition: all 300ms ease;
	}

	&__button:hover {
		background-color: var(--beige-dark1);
	}

	&__button:active {
		background-color: var(--beige-dark2);
	}
}