.event-form {
	&__status {
		margin-bottom: 1.25rem;
	}
	
	&__label {
		margin-bottom: 0.5rem;
		font-weight: 700;
	}

	&__radio {
		margin: 0 0.25rem 0 0;
	}
	
	&__status > label:first-of-type {
		margin-right: 1rem;
	}
	

	& > .text-input__label:last-of-type > .text-input__input {
		margin-bottom: 3rem;
	}
	
	&__save {
		margin-right: 0.75rem;
  	padding: 0.375rem 1.25rem;
  	background-color: var(--darkbrown);
  	font-size: 1rem;
		font-weight: 500;
  	color: white;
		transition: all 300ms ease;
	}

	&__save:hover {
		background-color: var(--darkbrown-dark1);
	}

	&__save:active {
		background-color: var(--darkbrown-dark2);
	}

	&__cancel {
  	padding: 0.375rem 1.25rem;
  	background-color: var(--beige);
  	font-size: 1rem;
		font-weight: 500;
		color: var(--lightbrown-dark1);
		transition: all 300ms ease;
	}

	&__cancel:hover {
		background-color: var(--beige-dark1);
	}

	&__cancel:active {
		background-color: var(--beige-dark2);
	}
}