.profile-dropdown-menu {
	position: absolute;
	top: 4rem;
	right: 0;
	z-index: 2;
	width: 16rem;
	padding: 1.425rem;
	border-radius: 5px;
	background-color: var(--lightbrown);
	filter: drop-shadow(0px 0px 10px var(--gray-dark2));
	color: white;

	@media(min-width: 700px) {
		padding: 1.625rem;
		top: 4.125rem;
	}

	&__name {
		font-size: 1.15rem;
		margin-bottom: 2.5rem;
	}
	
	&__buttons {
		margin-inline: -0.425rem;

		@media(min-width: 700px) {
			margin-inline: -0.625rem;
		}
	}

	&__button {
		width: 100%;
		padding: 0.25rem 0;
		border-radius: 5px;
		background-color: transparent;
		font-size: 1.15rem;
		text-align: left;
		text-indent: 0.425rem;
		color: white;
		transition: background-color 300ms ease;
		
		@media (min-width: 700px) {
			font-size: 1.25rem;
			text-indent: 0.625rem;
		}
	}

	&__button:hover {
		background-color: var(--lightbrown-dark1);
	}
	
	&__button:active {
		background-color: var(--lightbrown-dark2);
	}
}