.loading-spinner {
	height: 100%;
	padding-block: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;

	&--fullScreen {
		height: 100vh;
	}
}