.text-input {
	&__label {
		margin-bottom: 0.5rem;
		font-weight: 700;
	}

	&__input {
		display: block;
		width: min(100%, 30rem);
		margin-top: 0.5rem;
		margin-bottom: 1rem;
		padding: 0.75rem 1rem;
		border-radius: 5px;
		border: none;
		background-color: var(--beige);
		font-family: inherit;
		font-size: 1rem;
		transition: all 300ms ease;
	}

	&__input:hover {
		background-color: var(--beige-dark1); 
	}

	&__input:focus {
		outline: none;
		background-color: var(--beige-dark2);
	}

	&__input--readOnly {
		background-color: var(--gray-dark2);
		color: var(--darkgray-dark1);
	}

	&__input--readOnly:hover,
	&__input--readOnly:focus {
		background-color: var(--gray-dark2);
	}

	&--auth {
		font-weight: 400;
	}

	&--auth > &__input {
		width: 100%;
		background-color: white;
	}

	&--auth > &__input:hover {
		background-color: var(--gray);
	}

	&--auth > &__input:focus {
		background-color: white;
	}
}