.icon-button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2rem;
	min-width: 2rem;
	border-radius: 5px;
	background-color: var(--darkbrown);
	font-size: 1rem;
	font-weight: 500;
	color: white;
	transition: all 300ms ease;

	&:hover {
		background-color: var(--darkbrown-dark1);
	}

	&:active {
		background-color: var(--darkbrown-dark2);
	}

	&--beige {
		background-color: var(--beige);
		color: var(--lightbrown);

		&:hover {
			background-color: var(--beige-dark1);
		}

		&:active {
			background-color: var(--beige-dark2);
		}
	}

	&--lightbeige {
		background-color: var(--beige-light1);
		color: var(--lightbrown);

		&:hover {
			background-color: var(--beige);
		}

		&:active {
			background-color: var(--beige-dark1);
		}
	}

	&--white {
		background-color: white;
		color: var(--lightbrown);

		&:hover {
			background-color: var(--beige-light1);
		}

		&:active {
			background-color: var(--beige);
		}
	}
}