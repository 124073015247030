.save-close-buttons {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	gap: 0.25rem;
	margin-right: -0.625rem;

	&--shift {
		& .save-close-buttons__saveButton {
			background-color: var(--darkbrown-dark1);
		}

		& .save-close-buttons__saveTooltip {
			visibility: visible;
		}
	}

	&--shiftEnter {
		& .save-close-buttons__saveButton {
			background-color: var(--darkbrown-dark2);
		}
	}

	&:has(.save-close-buttons__saveButton:hover) .save-close-buttons__saveTooltip {
		visibility: visible;
	}

	&__saveTooltip {
		position: absolute;
		top: -2.25rem;
		left: -2.25rem;
		display: flex;
		flex-wrap: none;
		justify-content: center;
		align-items: center;
		gap: 0.2rem;
		z-index: 1;
		height: 2rem;
		width: calc(150% + 0.25rem);
		border-radius: 5px;
		background-color: var(--darkbrown);
		font-size: 0.65rem;
		color: white;
		filter: drop-shadow(0px 0px 5px var(--gray-dark2));
		visibility: hidden;
	}

	&__icon {
		height: 1rem;
		width: 1rem;
	}
}