.club-card {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;

	&__name {
		margin-bottom: 0;
		font-size: 1.15rem;
	}

	& .labeled-text {
		margin-block: 2rem;
	}
}