.full-screen-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	padding: 4rem 2rem;
	background-color: var(--beige);

	&__container {
		width: min(100%, 20rem);
	}

	&--wide {
		& .full-screen-container__container {
			width: min(100%, 70rem);
		}
	}
}