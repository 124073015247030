html {
	box-sizing: border-box;
	font-size: 100%;
}

*, 
*::before, 
*::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
	background-color: white;
	color: black;
 }

 button {
  border-radius: 5px;
  border: none;
  font-family: inherit;
  font-size: 1.25rem;
  cursor: pointer;
}