.header {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	margin-bottom: 2rem;

	&__back {
		height: 1.4rem;
		margin-right: 0.75rem;
		color: black;
		cursor: pointer;

		@media (min-width: 700px) {
			height: 1.75rem;
		}
	}

	&__title {
		display: inline;
		margin: 0;
		font-size: 2rem;
		line-height: 3rem;

		@media (min-width: 700px) {
			font-size: 2.5rem;
			line-height: 3.125rem;
		}
	}
	
	&__dropdown {
		position: relative;
	}
	
	&__profile {
		flex: 0 0 auto;
		height: 3rem;
		width: 3rem;
		background-color: var(--lightbrown);
		border-radius: 5px;
		font-size: 1.5rem;
		font-weight: 700;
		text-align: center;
		line-height: 3rem;
		color: white;
		cursor: pointer;
		user-select: none;
		transition: all 300ms ease;

		@media (min-width: 700px) {
				height: 3.125rem;
				width: 3.125rem;
				line-height: 3.125rem;
				font-size: 1.75rem;
		}
	}
	
	&__profile:hover {
		background-color: var(--lightbrown-dark1);
	}
	
	&__profile:active {
		background-color: var(--lightbrown-dark2);
	}
}