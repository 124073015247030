.status-message {
	width: min(100%, 30rem);
	padding: 0.75rem 1rem;
	border-radius: 5px;
	border: 0.2rem solid var(--darkgray);
	background-color: var(--gray-dark1);
	text-align: center;
	color: var(--darkgray);

	&--error {
		border-color: var(--red);
		background-color: var(--lightred);
		color: var(--red);
	}
}