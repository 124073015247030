.sort-dropdown {
	position: relative;

	&__sort-icon {
		width: 1rem;
	}

	&__dropdown-menu {
		position: absolute;
		top: 2.75rem;
		right: 0;
		z-index: 2;
		min-width: 15rem;
		padding: 1rem;
		border-radius: 5px;
		background-color: var(--beige);
		filter: drop-shadow(0px 0px 10px var(--gray-dark2));
	}

	&__title {
		margin-bottom: 1rem;
	}

	&__radio-label {
		display: block;
		margin-inline: -0.5rem;
		padding: 0.3rem 0.5rem;
		border-radius: 5px;
		cursor: pointer;
		user-select: none;
		transition: all 300ms ease;

		&:not(:last-of-type) {
			margin-bottom: 0.15rem
		}

		&:hover {
			background-color: var(--beige-dark1);
		}

		&:active {
			background-color: var(--beige-dark2);
		}

		&:has(.sort-dropdown__radio:checked) {
			background-color: var(--beige-dark2);
		}
	}

	&__radio {
		display: none;
	}
}